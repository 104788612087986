import { Fragment } from "react";
import Navigationbar from "./Navbar";
import './home.css';
import Experience from "./Components/Experience";
import About from "./Components/About";
import { SocialIcon } from 'react-social-icons/component'
import 'react-social-icons/linkedin'
import 'react-social-icons/github'
import 'react-social-icons/email'

function Profile() {
    return (
        <Fragment>
            <Navigationbar />
            <div className="body">
                <h3 className="title">Experiences</h3>
                <Experience />
                <h3 className="title">Skills</h3>
                <About />
                <br></br>
                <hr></hr>
                <div className="contact">
                    <a className="social-network" href="https://linkedin.com/in/indraaristya/" target='_blank' rel="noreferrer">
                        <SocialIcon network="linkedin" />
                    </a>
                    <a className="social-network" href="mailto:putuindraaristya@gmail.com" target='_blank' rel="noreferrer">
                        <SocialIcon network="email" />
                    </a>

                    <a className="social-network" href="https://github.com/indraaristya" target='_blank' rel="noreferrer">
                        <SocialIcon network="github" />
                    </a>
                </div>
            </div>
        </Fragment>
    )
}
  
export default Profile;