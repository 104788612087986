import { Fragment } from "react";
import "./experience.css";
import { Badge, Stack } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function Experience() {
    const newExperience = [
        {
            "office": "Mekari - Jakarta, Indonesia",
            "key": "mkr",
            "experiences": [
                {
                    "title": "Senior SDET I - Mekari",
                    "duration": "Feb 2024 - now",
                    "tasks": [
                        "maintain WEB and API automation for assigned products/tribes",
                        "create base structure and implement Playwright automation tools to product ESign",
                        "develop tools to generate k6 test script using JSON file",
                        "participate on develop tools to implement distrubuted load testing using k6",
                        "improve the load test features on QA Dashboard",
                        "research on AI-use to testing process",
                        "maintain QA Dashboard",
                        "participate to maintain Jenkins, and BitBucket pipeline related to automation"
                    ],
                    "tech_stack": ["typescript", "k6", "docker", "jenkins", "ruby on rails"]
                },
                {
                    "title": "SDET II - Mekari",
                    "duration": "Feb 2022 - Feb 2024",
                    "tasks": [
                        "improve QA Dashboard features; automation percentage and coverage insight",
                        "develop execute load test as a feature in QA Dashboard",
                        "implement and maintain API & Mobile automation",
                        "research on other automation tools",
                        "participate to maintain device farm, Jenkins, and automation on deployment pipeline"
                    ],
                    "tech_stack": ["ruby", "selenium", "appium", "ruby on rails", "docker", "jenkins"]
                }
            ]
        },
        {
            "office": "Kata.ai - Jakarta, Indonesia",
            "key": "kata",
            "experiences": [
                {
                    "title": "Quality Assurance Engineer - Kata.ai",
                    "office": "Kata.ai",
                    "duration": "Jul 2020 - Feb 2022",
                    "tasks": [
                        "define UAC per-story with approval by the Product Manager (also discussed with all team members)",
                        "create test plan and test cases for each development sprint time",
                        "done the E2E and Web UI testing before releasing new features",
                        "start automation testing using CodeceptJS"
                    ],
                    "tech_stack": ["javascript", "codeceptjs", "docker"]
                },
                {
                    "title": "Backend Engineer - Kata.ai",
                    "office": "Kata.ai",
                    "duration": "Mar 2020 - Jul 2020",
                    "tasks": [
                        "develop and maintain backend API for Business Dashboard (WhatsApp API)",
                        "develop, maintain and implement third-party integration that work with Chatbot using Kata Platform"
                    ],
                    "tech_stack": ["javascript", "nestjs", "redis", "rabbitmq", "mysql", "docker"]
                },
                {
                    "title": "Software Engineer Enterprise (Chatbot builder) - Kata.ai",
                    "office": "Kata.ai",
                    "duration": "Mar 2019 - Mar 2020",
                    "tasks": [
                        "building, implementing, and maintaining chatbot using Kata Platform",
                        "designing, building, implementing, and maintaining middleware solutions and third-party integrations that work with the chatbot",
                        "works closely with project manager, ux writter, and ux flow-bot to create realistics chatbot flow and conversation"
                    ],
                    "tech_stack": ["javascript", "nestjs", "python", "flask", "mysql"]
                }

            ]
        }
    ]
    return (
        <Fragment>
            <Tabs
                defaultActiveKey="mkr"
                id="justify-tab-example"
                className="mb-3"
                variant="pills"
                justify
            >
                {newExperience.map(function (object, _i) {
                    return (
                        <Tab eventKey={object['key']} title={object['office']}>
                            { object['experiences'].map(function(exp, _i) {
                                return (
                                    <Fragment>
                                        <h5 className="position">{exp['title']}</h5>
                                        <h5 className="duration">{exp['duration']}</h5>

                                        <Stack direction="horizontal" gap={2} >
                                            { exp['tech_stack'].map(function(tech, _i) {
                                                return <Badge pill bg="info">
                                                        {tech}
                                                    </Badge>
                                            })}
                                        </Stack>
                                        <p className="tasks">
                                            responsibilities:
                                            <ul>
                                                { exp['tasks'].map(function(task, _i) {
                                                    return <li>{task}</li>
                                                })}
                                            </ul>
                                        </p>
                                        <br></br>
                                    </Fragment>
                                )
                            })}

                        </Tab>
                    )
                })}
            </Tabs>
        </Fragment>
    )
}

export default Experience;