import { Fragment } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './home.css';

function Navigationbar() {
  return (
    <Fragment>
    <Navbar className='navbar-bg' expand="lg" variant='dark'>
      <Container>
        <a class="navbar-brand" href="/">IA.</a>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" >
          <Nav className="me-auto">
            <Nav.Link href="/" className="navbar-text">home</Nav.Link>
            <Nav.Link href="/profile" className="navbar-text">profile</Nav.Link>
            <Nav.Link href="/portfolios" className="navbar-text">portfolios</Nav.Link>
            <Nav.Link href="/writing" className="navbar-text">writing</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </Fragment>
    
  );
}

export default Navigationbar;