import { Fragment } from "react";
import Navigationbar from "./Navbar";
import './home.css';
import { Button, Badge, Stack } from "react-bootstrap";

function Home() {
    return (
        <Fragment>
            <Navigationbar />
            <div className="body" id='home'>
                <p>👋 HI MY NAME IS</p>
                <h2><b>PUTU INDRA</b></h2>
                <p className="subtitles">— Software Development Engineer in Test / QA Engineer</p>
                <p className="subtitles tagline">someone who involved in the team to automate the testing, and build tools to help the testing process</p>
                
                <Stack direction="horizontal" gap={2}>
                    <Badge pill bg="secondary">
                        team-work
                    </Badge>
                    <Badge pill bg="secondary">
                        problem-solving
                    </Badge>
                    <Badge pill bg="secondary">
                        honest
                    </Badge>
                    <Badge pill bg="secondary">
                        integrity
                    </Badge>
                </Stack>
                <div>
                    <a href="/profile">
                        <Button size='lg' className="button-content" variant="outline-secondary">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"/>
                        </svg>
                        &nbsp;more about me
                        </Button>
                    </a>
                </div>
            </div>
        </Fragment>
    )
}
  
export default Home;