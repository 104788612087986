import { Fragment } from "react";
import Navigationbar from "./Navbar";
import './home.css';
import Projects from "./Components/Projects";

function Portfolio() {
    return (
        <Fragment>
            <Navigationbar />
            <div className="body">
                <h3 className="title">Projects</h3>
                <Projects />
            </div>
        </Fragment>
    )
}
  
export default Portfolio;