import { Fragment } from "react";
import "./about.css";
import { ListGroup } from "react-bootstrap";

function About() {
    const about = {
        "personal_info": "I am Indra, based in Bali Indonesia, graduated as a bachelor of computer science in Telkom University in Bandung with speciality in intelligent, computing, and multimedia. My final project was analyze the performance of Pyramid Histogram of Oriented Gradient (PHOG) on balinese character recognition. I also have helped lecturer to grading and tutoring the students as a teaching assistant in Formal Language and Automata Theory, Basic Modeling and Simulation, and Artificial Intelligence courses.<br><br>On professional full-time work, I just started my career as a <b class='bold'>QA Engineer/SDET</b> focused on testing in order to <b class='bold'>prevent defects, improve, and deliver products with best-quality</b> and met the customer satisfaction.<br><br>Besides the manual testing execution, in previous work I also involved from the start of development process to review the requirement, create acceptance criteria, test plan, and test cases.<br>Other than that, building the test automation (API, Web, or Mobile) is also one of the duty. I've been (and still learning) to some of the automation tools, such as <b class='bold'>selenium and appium</b> written in gherkin using cucumber tools on Ruby, and <b class='bold'>rest assured</b> written in Java.<br><br>To catching up to recent technology, I also learning software engineering fields, such as create API using NestJS, build <i>mini-project</i> using ReactJS or NextJS.",
        "skills": {
            "programming": "Ruby, JavaScript / TypeScript, Python and Java",
            "fields": "Software Testing Automation, Software Development, and Agile Methodology",
            "framework": "Selenium, Appium, Postman, Playwright, k6, JMeter, Docker, Jenkins, Rails, NextJS, and ReactJS"
        }
    }
    
    return (
        <Fragment>
            <ListGroup as="ol">
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Programming</div>
                        {about['skills']['programming']}
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Tools/Framework</div>
                        {about['skills']['framework']}
                    </div>
                </ListGroup.Item>
                <ListGroup.Item
                    as="li"
                    className="d-flex justify-content-between align-items-start"
                >
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">Fields of Knowledge</div>
                        {about['skills']['fields']}
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </Fragment>
    )
}
  
export default About;